import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "shop_buys"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_card = _resolveComponent("van-card");
  const _component_van_checkbox = _resolveComponent("van-checkbox");
  const _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
  const _component_van_submit_bar = _resolveComponent("van-submit-bar");
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: "购物车",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $setup.onClickLeft
  }, null, 8, ["onClickLeft"]), _createVNode(_component_van_checkbox_group, {
    class: "card-goods",
    modelValue: $data.checkedGoods,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.checkedGoods = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.goods, item => {
      return _openBlock(), _createBlock(_component_van_checkbox, {
        class: "card-goods__item",
        key: item.id,
        name: item.id
      }, {
        default: _withCtx(() => [_createVNode(_component_van_card, {
          title: item.title,
          desc: item.desc,
          num: item.num,
          price: $options.formatPrice(item.price),
          thumb: item.thumb
        }, null, 8, ["title", "desc", "num", "price", "thumb"])]),
        _: 2
      }, 1032, ["name"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_van_submit_bar, {
    price: $options.totalPrice,
    disabled: !$data.checkedGoods.length,
    "button-text": $options.submitBarText,
    onSubmit: $options.toapply,
    class: "my_submitbar"
  }, {
    tip: _withCtx(() => [_createTextVNode(" 你的收货地址不支持配送, "), _createElementVNode("span", {
      onClick: _cache[2] || (_cache[2] = (...args) => $setup.onClickLink && $setup.onClickLink(...args))
    }, "修改地址")]),
    default: _withCtx(() => [_createVNode(_component_van_checkbox, {
      modelValue: $setup.checked,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.checked = $event),
      onClick: _ctx.toggleAll
    }, {
      default: _withCtx(() => [_createTextVNode("全选")]),
      _: 1
    }, 8, ["modelValue", "onClick"])]),
    _: 1
  }, 8, ["price", "disabled", "button-text", "onSubmit"]), _createVNode(_component_van_tabbar, {
    modelValue: $setup.active,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.active = $event)
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      icon: "wap-home-o",
      name: "shop-home",
      replace: "",
      to: "shop-home"
    }, {
      default: _withCtx(() => [_createTextVNode("首页")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "apps-o",
      name: "shop-class",
      replace: "",
      to: "shop-class"
    }, {
      default: _withCtx(() => [_createTextVNode("分类")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "cart-o",
      name: "shop-buys",
      replace: "",
      to: "shop-buys"
    }, {
      default: _withCtx(() => [_createTextVNode("购物车")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "user-o",
      name: "shop-ours",
      replace: "",
      to: "shop-ours"
    }, {
      default: _withCtx(() => [_createTextVNode("我的")]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]);
}