import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import { Checkbox, CheckboxGroup, Card, SubmitBar, Toast } from 'vant';
// 手动引入 Toast 组件的样式
import 'vant/es/toast/style';
import wx from 'weixin-jsapi';
import axios from "axios";
export default {
  setup() {
    // Navigation Bar导航条 - 返回
    const onClickLeft = () => history.back();
    // Tabbar 标签栏 - 选中购物车
    const active = ref('shop-buys');
    // Toast 轻提示 - 点击结算
    const onSubmit = () => {
      // this.wxlogin();
      Toast('没有下一步了');
    };
    // Toast 轻提示 - 修改地址
    const onClickLink = () => Toast('修改地址');
    // Checkbox 复选框 - 选中
    const checked = ref(true);
    return {
      onClickLeft,
      active,
      onSubmit,
      onClickLink,
      checked
    };
  },
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data() {
    return {
      checkedGoods: ['1', '2', '3'],
      goods: [{
        id: '1',
        title: '进口香蕉',
        desc: '约250g，2根',
        price: 200,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/2f9a36046449dafb8608e99990b3c205.jpeg'
      }, {
        id: '2',
        title: '陕西蜜梨',
        desc: '约600g',
        price: 690,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/f6aabd6ac5521195e01e8e89ee9fc63f.jpeg'
      }, {
        id: '3',
        title: '美国伽力果',
        desc: '约680g/3个',
        price: 2680,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/320454216bbe9e25c7651e1fa51b31fd.jpeg'
      }],
      appid: 'wx95950b26c2eb88ac',
      url: 'https://shop.crystal-map.com/#/home'
    };
  },
  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return '结算' + (count ? `(${count})` : '');
    },
    totalPrice() {
      return this.goods.reduce((total, item) => total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0), 0);
    }
  },
  created() {
    // this.userId = JSON.parse(Cookie.get("user")).id;//这是在我页面需要获取的userid，不需要的可自行删悼，不在支付代码范围
    this.getConfig();
  },
  methods: {
    formatPrice(price) {
      return (price / 100).toFixed(2);
    },
    getConfig() {
      console.log(window.location.href);
      var url = window.location.href;
      axios.post('**此处写后台提供获取jsapi相关配置的接口**', {
        encodeUrl: url.split("#")[0]
        // encodeUrl:Encrypt(url.split("#")[0])  //直接丢弃#及后面的字符串   注意这里Encrypt因为我的项目里使用了ase加密解密，所以这么写的
      }).then(function (response) {
        if (response.data.flag == true) {
          var data = JSON.parse(response.data.data);
          // var data = JSON.parse(Decrypt(response.data.data));//将解密后的字符串转为对象  Decrypt这里是解密，不需要的就直接过滤悼
          console.log(data);
          //下列的data.均为后台接口返回的字段，比如我的项里里返回的是 appid,timestamp,nonceStr,signature
          wx.config({
            debug: true,
            //这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
            appId: data.appid,
            timestamp: data.timestamp,
            nonceStr: data.noncestr,
            signature: data.signature,
            jsApiList: ['chooseWXPay']
          });
          wx.ready(function () {
            wx.checkJsApi({
              jsApiList: ['chooseWXPay'],
              success: function (res) {
                console.log("seccess");
                console.log(res);
              },
              fail: function (res) {
                console.log("fail");
                console.log(res);
              }
            });
          });
        } else {
          Toast({
            message: response.data.detailMsg
          });
        }
      }).catch(function () {
        Toast({
          //提示引用的是mint-UI里toast
          message: '获取config失败，请重试'
        });
      });
    },
    //报名缴费   ( 支付按钮绑定@click="toapply()"事件)
    toapply(id) {
      var $this = this;
      axios.post('**此处写后台提供的获取支付json数据接口**', {
        encodeStr: id //项目里的加密
      }).then(function (response) {
        if (response.data.flag == true) {
          var data = JSON.parse(response.data.data); //将解密后的字符串转为对象
          console.log(data);
          wx.ready(function () {
            wx.chooseWXPay({
              appId: data.appId,
              timestamp: data.timeStamp,
              // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: data.nonceStr,
              // 支付签名随机串，不长于 32
              package: data.package,
              // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: data.signType,
              // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: data.paySign,
              // 支付签名
              success: function (res) {
                //跳转到支付成功页面有这个页面
                $this.$router.push({
                  path: "/success_page",
                  name: "success_page"
                });
                console.log(res);
              },
              cancel: function () {
                //提示引用的是mint-UI里toast
                Toast('已取消支付');
              },
              fail: function () {
                Toast('支付失败，请重试');
              }
            });
          });
        } else {
          Toast({
            message: '获取支付信息失败，请重试'
          });
        }
      }).catch(function (error) {
        Toast({
          message: '获取订单信息失败，请重试'
        });
        console.log(error);
      });
    }
  }
};